/* You can add global styles to this file, and also import other style files */


@import "@angular/material";
@import "bootstrap/dist/css/bootstrap.css";

html, body { height: 100%; }
body {
    width: 100%;
    height: calc(100vh - 0px);
}
